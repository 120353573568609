import { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography, Skeleton } from '@mui/material';
import { useLocation } from 'react-router-dom';

// Our Components
import AnalyticsTable from 'components/Table/AnalyticsTable';
import Dropdown from 'components/Dropdown/Dropdown';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useGetClientLiabilitiesAnalytics from 'hooks/analytics/useGetClientLiabilitiesForAnalytics';
import useQueryGetAssumptions from 'hooks/analytics/assumptions/useQueryGetAssumptions';
import useMutateGetAnalyticsOffer from 'hooks/analytics/useMutateGetAnalyticsOffer';

// Our utils
import { dataIsValid, roundToDecimal } from 'shared/utils';

// Our Constants
import { MORTGAGE } from 'shared/constants';

// Local Constants
const DEFAULT_RATE = '6';

const createLiabilityLabels = (clientLiabilities) => {
	const isValidLiabilities =
		Array.isArray(clientLiabilities) && clientLiabilities.length > 0;

	if (isValidLiabilities) {
		return clientLiabilities.map(
			({ lender, tradelineId }) => `${lender} - ${tradelineId}`
		);
	}

	return ['No Liabilities Found'];
};

const stripTradelineId = (liabilityLabel) => {
	const isValidLabel =
		liabilityLabel !== '' && liabilityLabel !== 'No Liabilities Found';

	if (isValidLabel) {
		const splitLabel = liabilityLabel.split(' ');

		const tradeLineId = splitLabel[splitLabel.length - 1];

		return tradeLineId;
	}

	return null;
};

function LoanComparisonTopSection({
	analyticsSavings,
	analyticsNPV,
	analyticsAmortization,
	analyticsUpsidePotential
}) {
	const { state } = useLocation();

	const prePopulatedClientData = state?.clientSelected;

	const isSomeClientSelected = prePopulatedClientData ?? false;

	const { data: assumptionsData, isSuccess: isSuccessAssumptions } =
		useQueryGetAssumptions(prePopulatedClientData?.id);

	// Mutations
	const analyticsOffer = useMutateGetAnalyticsOffer();

	// Client State
	const [selectedLiabilityData, setSelectedLiabilityData] = useState('');
	const selectedLiabilityDataRef = useRef(null);

	// Analytics Table State
	const [bestOfferBalance, setBestOfferBalance] = useState('');
	const [bestOfferInterestRate, setBestOfferInterestRate] =
		useState(DEFAULT_RATE);
	const [bestOfferTenure, setBestOfferTenure] = useState('');

	const { isLoading, isSuccess, data } = useGetClients('');

	const enabledGatherLiabilitiesCall =
		isSuccess && dataIsValid(data) && !!isSomeClientSelected;

	const {
		isLoading: isAnalyticsOfferLoading,
		isSuccess: isAnalyticsOfferSuccess,
		data: analyticsOfferTableData
	} = analyticsOffer;

	const {
		isLoading: clientLiabilitiesIsLoading,
		data: clientLiabilitiesData,
		isSuccess: clientLiabilitiesIsSuccess
	} = useGetClientLiabilitiesAnalytics(
		prePopulatedClientData?.id ?? 0,
		enabledGatherLiabilitiesCall,
		(clientWalletOverviewData) => {
			const clientLiabilities = clientWalletOverviewData;

			if (dataIsValid(clientLiabilities))
				return clientLiabilities.filter(
					(currentClientLiability) =>
						currentClientLiability.tradeLineType === MORTGAGE
				);

			return [];
		}
	);

	const isLiabilityDataEmpty =
		clientLiabilitiesIsSuccess &&
		Array.isArray(clientLiabilitiesData) &&
		clientLiabilitiesData.length < 1;

	const requestOffer = () => {
		const liabilityId = stripTradelineId(selectedLiabilityData);

		const createAssumptionArgs = () => {
			const isAssumptionOffer =
				bestOfferBalance !== '' &&
				bestOfferInterestRate !== '' &&
				bestOfferTenure !== '';
			if (isAssumptionOffer)
				return {
					assumptionOffer: {
						balance: bestOfferBalance,
						rate: bestOfferInterestRate,
						termInMonth: +bestOfferTenure * 12
					}
				};
			return {};
		};

		const additionalArgs = createAssumptionArgs();

		const analyticsRequestPayload = {
			liabilityId,
			expectedAnnualReturn:
				assumptionsData && assumptionsData.assets[0]
					? assumptionsData.assets[0].firstGrowth
					: DEFAULT_RATE,
			...additionalArgs
		};

		analyticsOffer.mutate(analyticsRequestPayload, {
			onSuccess: (analyticsOfferData) => {
				const { bestOffer } = analyticsOfferData;

				const {
					balance: analyticsOfferBalance,
					interestRate: analyticsOfferInterestRate,
					remainingMonth: analyticsOfferTenure
				} = bestOffer;

				setBestOfferInterestRate(
					roundToDecimal(analyticsOfferInterestRate)
				);

				setBestOfferBalance(analyticsOfferBalance);
				setBestOfferTenure(analyticsOfferTenure / 12);
			}
		});

		analyticsAmortization.mutate(analyticsRequestPayload);
		analyticsSavings.mutate(analyticsRequestPayload);
		analyticsNPV.mutate(analyticsRequestPayload);
		analyticsUpsidePotential.mutate(analyticsRequestPayload);
	};

	const currentlySelectedClientLiabilitiesLabels = createLiabilityLabels(
		clientLiabilitiesData
	);

	const clearAllPageData = (clearSelectedLiabilityData = true) => {
		if (clearSelectedLiabilityData) {
			setSelectedLiabilityData('');
		}
		setBestOfferBalance('');
		setBestOfferInterestRate('');
		setBestOfferTenure('');
		analyticsOffer.reset();
		analyticsAmortization.reset();
		analyticsSavings.reset();
		analyticsNPV.reset();
		analyticsUpsidePotential.reset();
	};

	useEffect(() => {
		const isSelectedLiabilityDataChanged =
			selectedLiabilityDataRef.current !== undefined &&
			selectedLiabilityData !== '' &&
			selectedLiabilityDataRef.current !== '' &&
			selectedLiabilityDataRef.current !== selectedLiabilityData;

		if (isSelectedLiabilityDataChanged) {
			clearAllPageData(false);
		}

		// Update the ref with the new value
		selectedLiabilityDataRef.current = selectedLiabilityData;
	}, [selectedLiabilityData]);

	useEffect(() => {
		clearAllPageData();
	}, [prePopulatedClientData, clientLiabilitiesData, assumptionsData]);

	useEffect(() => {
		if (
			isSuccessAssumptions &&
			assumptionsData &&
			assumptionsData.assets[0]
		) {
			setBestOfferInterestRate(
				roundToDecimal(assumptionsData.assets[0].firstGrowth)
			);
		}
	}, [assumptionsData, isSuccessAssumptions]);

	if (isLoading) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Skeleton
						variant="rectangular"
						height={350}
						sx={{ marginBottom: 4 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Skeleton
						variant="rectangular"
						height={350}
						sx={{ marginBottom: 4 }}
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container>
			<Grid item xs={12} sx={{ marginBottom: 4 }}>
				<Box>
					<Typography
						variant="h1Gascogne"
						sx={{
							marginBottom: 1,
							display: 'block'
						}}
					>
						Loan Comparison Analyzer
					</Typography>
					<Typography variant="body2" sx={{ display: 'block' }}>
						Use this analytics tool to compare different loan
						options on your mortgage. Our software will auto
						populate available offers that we see on our platform
						for your mortgage. However, you can also input your own
						loan offers into the software.
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box
					sx={{
						height: '100%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{!isLiabilityDataEmpty ? (
							<Typography
								variant="subtitle1"
								sx={{ marginRight: 11 }}
							>
								Liabilities:
							</Typography>
						) : (
							<Typography variant="subtitle1">
								No Liabilities Found
							</Typography>
						)}
						{isSomeClientSelected && clientLiabilitiesIsLoading && (
							<Skeleton sx={{ width: 300, height: 53 }} />
						)}

						{isSomeClientSelected &&
							!clientLiabilitiesIsLoading &&
							!isLiabilityDataEmpty && (
								<Dropdown
									legacy
									sx={{ width: 300 }}
									items={
										currentlySelectedClientLiabilitiesLabels
									}
									onChange={setSelectedLiabilityData}
									selected={selectedLiabilityData}
								/>
							)}
					</Box>
					<Box
						sx={{
							height: 89,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<TertiaryButton
							sx={{ paddingTop: 2, paddingBottom: 2 }}
							onClick={requestOffer}
							isDisabled={
								!isSomeClientSelected ||
								selectedLiabilityData === '' ||
								clientLiabilitiesIsLoading ||
								selectedLiabilityData === 'No Liabilities Found'
							}
						>
							Generate Analyses
						</TertiaryButton>
					</Box>
				</Box>
			</Grid>

			<Grid
				container
				item
				xs={12}
				xl={6}
				sx={{ marginBottom: 4, maxHeight: 312 }}
			>
				{isAnalyticsOfferLoading && (
					<Skeleton
						variant="rectangular"
						sx={{ height: 350, width: '95%' }}
					/>
				)}
				{isAnalyticsOfferSuccess && selectedLiabilityData !== '' && (
					<AnalyticsTable
						analyticsOffer={analyticsOfferTableData}
						assumptionsData={assumptionsData}
						bestOfferBalance={bestOfferBalance}
						setBestOfferBalance={setBestOfferBalance}
						bestOfferInterestRate={bestOfferInterestRate}
						setBestOfferInterestRate={setBestOfferInterestRate}
						bestOfferTenure={bestOfferTenure}
						setBestOfferTenure={setBestOfferTenure}
					/>
				)}
			</Grid>
		</Grid>
	);
}

export default LoanComparisonTopSection;
