import { useState } from 'react';

// Our Components
import PageHeading from 'components/PageHeading/PageHeading';
import SettingsTabs from 'components/Settings/SettingsTabs';

function SettingsPage() {
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	return (
		<>
			<PageHeading title="Settings" />

			<SettingsTabs
				tabPanelSelected={tabPanelSelected}
				setTabPanelSelected={setTabPanelSelected}
			/>
		</>
	);
}

export default SettingsPage;
