import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

function DropdownLabel({ sx, labelText, labelVariant, legacy }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	if (legacy) {
		// intentionally did not add marginBottom due to changes required in other places currently using Dropdown
		return <Box sx={{ backgroundColor: primary.white, ...sx }} />;
	}

	return (
		<Typography
			variant={labelVariant}
			sx={{
				minHeight: 20,
				marginBottom: 1,
				visibility: labelText ? 'visible' : 'hidden',
				...sx
			}}
		>
			{labelText}
		</Typography>
	);
}

DropdownLabel.propTypes = {
	labelText: PropTypes.oneOfType([PropTypes.string]),
	labelVariant: PropTypes.string,
	sx: CommonPropTypes.sx
};

DropdownLabel.defaultProps = {
	labelText: null,
	labelVariant: 'subtitle2',
	sx: {}
};

export default DropdownLabel;
