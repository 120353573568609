import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';
// Our Components
import ChangePassword from 'components/Settings/ChangePassword';
import SettingsPanel from 'components/Settings/SettingsForm';
import PlanBillingTab from 'components/Settings/PlanBillingTab';
import DynamicTabs from 'components/Tabs/DynamicTabs';

// Our Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

const TAB_NAME = [
	{
		type: 'My Profile'
	},
	{
		type: 'Change Password'
	},
	{
		type: 'Plan & Billing'
	}
];

function SettingsTabs({ tabPanelSelected, setTabPanelSelected }) {
	const SoraTheme = useTheme();
	const { disabledGrey } = SoraTheme.palette.primary;

	const queryClient = useQueryClient();

	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	// Retrieve role from react query
	const loggedInUserProfileData = queryClient.getQueryData(USERPROFILEDATA);

	const isAdvisor = loggedInUserProfileData?.role === 'ADVISOR';

	const tabPanelItems = useMemo(() => {
		const items = [
			{
				children: <SettingsPanel />
			},
			{
				children: <ChangePassword />
			}
		];

		if (isAdvisor) return [...items, { children: <PlanBillingTab /> }];

		return items;
	}, []);

	return (
		<Grid container item xs={12}>
			<DynamicTabs
				tabItems={TAB_NAME}
				tabItemSX={{
					padding: 0,
					textTransform: 'none',
					marginRight: 5
				}}
				tabsSX={{
					marginBottom: 4,
					borderBottom: `1px solid ${disabledGrey}`,
					'& .MuiTabs-scrollButtons.Mui-disabled': {
						opacity: 0.3
					},
					maxWidth: 'fit-content'
				}}
				tabPanelItems={tabPanelItems}
				value={tabPanelSelected}
				handleChange={handleTabSelection}
				aria-label="Settings tabs"
			/>
		</Grid>
	);
}

SettingsTabs.propTypes = {
	tabPanelSelected: PropTypes.number.isRequired,
	setTabPanelSelected: PropTypes.func.isRequired
};

export default SettingsTabs;
