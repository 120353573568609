import { useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import AvatarUpload from 'components/FileUpload/AvatarUpload';
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import ProfilePicturePlaceHolder from 'components/Avatar/ProfilePicturePlaceHolder';

// our Hooks
import useGetProfilePictureOrCompanyLogo from 'hooks/advisorProfile/useGetProfilePictureOrCompanyLogo';
import { PrimaryButton } from 'components/Button/Button';

const getDescriptionBasedOnIsCorporate = (isCorporate) => {
	if (isCorporate)
		return 'Upload an image, preferably a .png with a transparent background. Max file size: 5MB.';
	return 'Upload a .png or .jpg image. Max file size: 5MB.';
};

// Main Component
function ProfileAvatar({ isCorporate }) {
	// Constants
	const modalTitle = isCorporate
		? 'Update your company logo here'
		: 'Update your profile photo here';

	const labelHeading = isCorporate ? 'Company Logo' : 'Profile Photo';

	// Styling based on whether is profile picture or company logo
	const borderRadius = isCorporate ? 0 : 200;
	const displayDimensions = isCorporate
		? { maxHeight: 200, maxWidth: 600 }
		: { height: 200, width: 200 };

	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { isLoading, isSuccess, data } =
		useGetProfilePictureOrCompanyLogo(isCorporate);

	const closeModal = useCallback(() => setIsModalOpen(false), []);

	if (isLoading) return <Loader size={60} />;

	if (isSuccess) {
		const { imageUrl, timestamp } = data;
		const displayProfilePicture = imageUrl;
		const displayFallBack = !imageUrl;

		const labelMarginRightStyling = isCorporate && displayFallBack ? 3 : 0;

		const description = getDescriptionBasedOnIsCorporate(isCorporate);

		return (
			<>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						'&:hover': {
							cursor: 'pointer',
							opacity: 0.6
						}
					}}
					onClick={() => setIsModalOpen(true)}
				>
					<Typography
						variant="subtitle1"
						sx={{ marginRight: labelMarginRightStyling }}
					>
						{labelHeading}
					</Typography>
					<Typography
						variant="body2"
						sx={{ marginRight: labelMarginRightStyling }}
					>
						{description}
					</Typography>

					{displayProfilePicture && (
						<Box
							key={timestamp}
							component="img"
							src={imageUrl}
							sx={{
								borderRadius,
								...displayDimensions
							}}
						/>
					)}

					{displayFallBack && (
						<ProfilePicturePlaceHolder isCorporate={isCorporate} />
					)}
				</Box>

				<Modal
					title={modalTitle}
					isOpen={isModalOpen}
					handleClose={closeModal}
				>
					<Grid container>
						<Grid item xs={12}>
							<AvatarUpload
								appear
								isCorporate={isCorporate}
								withoutAnimation
							/>
						</Grid>
						<Grid item xs={12}>
							<PrimaryButton
								sx={{ marginTop: 4 }}
								onClick={closeModal}
							>
								Close
							</PrimaryButton>
						</Grid>
					</Grid>
				</Modal>
			</>
		);
	}
}

ProfileAvatar.propTypes = {
	isCorporate: PropTypes.bool
};

ProfileAvatar.defaultProps = {
	isCorporate: false
};

export default ProfileAvatar;
