// Our Components - Forms
import AutoRefiForm from 'components/Forms/NewLoanForms/auto/AutoRefiForm';
import CashOutRefi from 'components/Forms/NewLoanForms/otherLoanForms/CashOutRefiForm';
import ConstructionLoanInquiryForm from 'components/Forms/NewLoanForms/otherLoanForms/ConstructionLoanForm';
import FarmLoan from 'components/Forms/NewLoanForms/otherLoanForms/FarmLoanForm';
import HelocLoanForm from 'components/Forms/NewLoanForms/heloc/HelocForm';
import LotForm from 'components/Forms/NewLoanForms/otherLoanForms/LotLoanForm';
import MortgageRefinanceLoanForm from 'components/Forms/NewLoanForms/mortgage/MortgageRefinanceForm';
import NewAutoLoanForm from 'components/Forms/NewLoanForms/auto/NewAutoForm';
import NewMortgageLoanForm from 'components/Forms/NewLoanForms/mortgage/NewMortgageForm';
import NewPersonalLoan from 'components/Forms/NewLoanForms/personal/NewPersonalForm';
import PersonalRefinanceLoanForm from 'components/Forms/NewLoanForms/personal/PersonalRefinanceForm';
import ReverseMortgage from 'components/Forms/NewLoanForms/otherLoanForms/ReverseMortgageForm';
import SmallBusinessLoan from 'components/Forms/NewLoanForms/otherLoanForms/SmallBusinessForm';
import StudentLoanRefinanceLoanForm from 'components/Forms/NewLoanForms/student/StudentLoanRefinanceForm';
import SblocLoanForm from 'components/Forms/NewLoanForms/SBLOC/SblocLoanForm';
import OtherLoanForm from 'components/Forms/NewLoanForms/otherLoanForm/OtherLoanForm';

// Our Constants
import { HELOC } from 'shared/constants';

function LoanForms({
	advisorInfo,
	clientFirstName,
	clientLastName,
	clientProfileData,
	clientWalletOverviewData,
	selectedLoanType,
	selectedClient,
	selectedClientLoan,
	isNewClientSelected,
	isClientNameReady
}) {
	const { advisorName, advisorEmail, advisorCompany, advisorWebsite } =
		advisorInfo;

	const clientProfileDataWithFallBack = clientProfileData ?? {
		id: null,
		firstName: clientFirstName,
		lastName: clientLastName
	};

	const isClientNameProvided = isNewClientSelected ? isClientNameReady : true;

	return (
		<>
			{selectedLoanType === 'Securities Based Line' && selectedClient && (
				<SblocLoanForm
					clientId={clientProfileDataWithFallBack?.id}
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
				/>
			)}
			{selectedLoanType === 'Automotive Loan (New)' && selectedClient && (
				<NewAutoLoanForm
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					cityValue={clientWalletOverviewData?.address?.city}
					stateValue={clientWalletOverviewData?.address?.state}
					clientId={clientProfileDataWithFallBack?.id}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Automotive Loan (Refinance)' &&
				selectedClient && (
					<AutoRefiForm
						isClientNameProvided={isClientNameProvided}
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						clientId={clientProfileDataWithFallBack?.id}
						creditScoreValue={
							clientProfileDataWithFallBack?.creditScore
						}
						currentLoanInterestRateValue={
							selectedClientLoan?.interestRate
						}
						currentLoanRemainingTermValue={
							selectedClientLoan?.balanceTenureMonth
						}
						loanAmountValue={selectedClientLoan?.outstandingBalance}
						loanType={selectedLoanType}
						firstName={
							clientProfileDataWithFallBack?.firstName || ''
						}
						lastName={clientProfileDataWithFallBack?.lastName || ''}
						emailAddressValue={clientProfileDataWithFallBack?.email}
						dateOfBirth={clientProfileDataWithFallBack?.dob}
						stateValue={
							clientProfileDataWithFallBack?.address?.state
						}
						vinValue={selectedClientLoan?.vehicleInfo?.vin}
						conditionValue={
							selectedClientLoan?.vehicleInfo?.condition
						}
						mileage={selectedClientLoan?.vehicleInfo?.mileage}
						refinancingTradelineId={selectedClientLoan?.tradelineId}
					/>
				)}

			{selectedLoanType === 'Cash Out Refinance' && selectedClient && (
				<CashOutRefi
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					addressLine1Value={
						clientProfileDataWithFallBack?.address?.addressLine1
					}
					addressLine2Value={
						clientProfileDataWithFallBack?.address?.addressLine2
					}
					balanceTenureMonth={
						selectedClientLoan?.balanceTenureMonth ?? 0
					}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					clientId={clientProfileDataWithFallBack?.id}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					annualIncome={clientWalletOverviewData?.annualIncome}
					veteranStatusValue={clientProfileDataWithFallBack?.veteran}
					estimatedValue={
						selectedClientLoan?.clientHome?.estimatedValue ?? ''
					}
					primaryUseValue={
						selectedClientLoan?.clientHome?.propertyType
					}
					cityValue={selectedClientLoan?.clientHome?.city}
					stateValue={selectedClientLoan?.clientHome?.state}
					zipCodeValue={selectedClientLoan?.clientHome?.zipcode}
					currentOutstandingBalance={
						selectedClientLoan?.outstandingBalance ?? ''
					}
					currentInterestRateValue={
						selectedClientLoan?.interestRate ?? '0'
					}
					refinancingTradelineId={selectedClientLoan?.tradelineId}
					loanType={selectedLoanType}
					dwellingType={selectedClientLoan?.clientHome?.dwellingType}
				/>
			)}

			{selectedLoanType === 'Construction Loan' && selectedClient && (
				<ConstructionLoanInquiryForm
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					annualIncome={clientWalletOverviewData?.annualIncome}
					clientId={clientProfileDataWithFallBack?.id}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Farm Loan' && selectedClient && (
				<FarmLoan
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					clientId={clientProfileDataWithFallBack?.id}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === HELOC && selectedClient && (
				<HelocLoanForm
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					clientId={clientProfileDataWithFallBack?.id}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					annualIncome={clientWalletOverviewData?.annualIncome}
					outstandingBalanceValue={
						selectedClientLoan?.outstandingBalance
					}
					estimatedValue={
						selectedClientLoan?.clientHome?.estimatedValue
					}
					primaryUseValue={
						selectedClientLoan?.clientHome?.propertyType
					}
					addressLine1Value={
						selectedClientLoan?.clientHome?.addressLine1
					}
					addressLine2Value={
						selectedClientLoan?.clientHome?.addressLine2
					}
					cityValue={selectedClientLoan?.clientHome?.city}
					stateValue={selectedClientLoan?.clientHome?.state}
					zipCodeValue={selectedClientLoan?.clientHome?.zipcode}
					refinancingTradelineId={selectedClientLoan?.tradelineId}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Lot Loan' && selectedClient && (
				<LotForm
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					clientId={clientProfileDataWithFallBack?.id}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					annualIncome={clientWalletOverviewData?.annualIncome}
					stateValue={clientProfileDataWithFallBack?.address?.state}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Mortgage Loan (New)' && selectedClient && (
				<NewMortgageLoanForm
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					addressLine1Value={
						clientProfileDataWithFallBack?.address?.addressLine1
					}
					addressLine2Value={
						clientProfileDataWithFallBack?.address?.addressLine2
					}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					annualIncome={clientWalletOverviewData?.annualIncome}
					veteranStatusValue={clientProfileDataWithFallBack?.veteran}
					cityValue={clientWalletOverviewData?.address?.city}
					stateValue={clientWalletOverviewData?.address?.state}
					clientId={clientProfileDataWithFallBack?.id}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Mortgage Loan (Refinance)' &&
				selectedClient && (
					<MortgageRefinanceLoanForm
						isClientNameProvided={isClientNameProvided}
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						addressLine1Value={
							clientProfileDataWithFallBack?.address?.addressLine1
						}
						addressLine2Value={
							clientProfileDataWithFallBack?.address?.addressLine2
						}
						balanceTenureMonth={
							selectedClientLoan?.balanceTenureMonth ?? 0
						}
						firstName={
							clientProfileDataWithFallBack?.firstName || ''
						}
						lastName={clientProfileDataWithFallBack?.lastName || ''}
						clientId={clientProfileDataWithFallBack?.id}
						creditScoreValue={
							clientProfileDataWithFallBack?.creditScore
						}
						currentOutstandingBalance={
							selectedClientLoan?.outstandingBalance
						}
						currentInterestRateValue={
							selectedClientLoan?.interestRate
						}
						emailAddressValue={clientProfileDataWithFallBack?.email}
						annualIncome={clientWalletOverviewData?.annualIncome}
						veteranStatusValue={
							clientProfileDataWithFallBack?.veteran
						}
						estimatedValue={
							selectedClientLoan?.clientHome?.estimatedValue ?? ''
						}
						primaryUseValue={
							selectedClientLoan?.clientHome?.propertyType
						}
						cityValue={selectedClientLoan?.clientHome?.city}
						stateValue={selectedClientLoan?.clientHome?.state}
						zipCodeValue={selectedClientLoan?.clientHome?.zipcode}
						refinancingTradelineId={selectedClientLoan?.tradelineId}
						loanType={selectedLoanType}
						dwellingType={
							selectedClientLoan?.clientHome?.dwellingType
						}
					/>
				)}

			{selectedLoanType === 'Personal Loan (New)' && selectedClient && (
				<NewPersonalLoan
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					dobValue={clientProfileDataWithFallBack?.dob}
					clientId={clientProfileDataWithFallBack?.id}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					annualIncome={clientWalletOverviewData?.annualIncome}
					assetsAmount={clientWalletOverviewData?.assetsAmount}
					employmentStatusValue={
						clientProfileDataWithFallBack?.employmentStatus
					}
					employerValue={clientProfileDataWithFallBack?.employer}
					veteranStatusValue={clientProfileDataWithFallBack?.veteran}
					addressLine1Value={
						clientProfileDataWithFallBack?.address?.addressLine1
					}
					addressLine2={
						clientProfileDataWithFallBack?.address?.addressLine2
					}
					cityValue={clientProfileDataWithFallBack?.address?.city}
					stateValue={clientProfileDataWithFallBack?.address?.state}
					zipCodeValue={
						clientProfileDataWithFallBack?.address?.zipcode
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					currentTermValue={selectedClientLoan?.currentTerm}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Personal Loan (Refinance)' &&
				selectedClient && (
					<PersonalRefinanceLoanForm
						isClientNameProvided={isClientNameProvided}
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						clientId={clientProfileDataWithFallBack?.id}
						creditScoreValue={
							clientProfileDataWithFallBack?.creditScore
						}
						firstName={
							clientProfileDataWithFallBack?.firstName || ''
						}
						lastName={clientProfileDataWithFallBack?.lastName || ''}
						veteranStatusValue={
							clientProfileDataWithFallBack?.veteran
						}
						employerValue={clientProfileDataWithFallBack?.employer}
						employmentStatusValue={
							clientProfileDataWithFallBack?.employmentStatus
						}
						jobTitleValue={clientProfileDataWithFallBack?.jobTitle}
						dobValue={clientProfileDataWithFallBack?.dob}
						addressLine1Value={
							clientProfileDataWithFallBack?.address?.addressLine1
						}
						addressLine2={
							clientProfileDataWithFallBack?.address?.addressLine2
						}
						cityValue={clientProfileDataWithFallBack?.address?.city}
						stateValue={
							clientProfileDataWithFallBack?.address?.state
						}
						zipCodeValue={
							clientProfileDataWithFallBack?.address?.zipcode
						}
						emailAddressValue={clientProfileDataWithFallBack?.email}
						annualIncome={clientWalletOverviewData?.annualIncome}
						assetsAmount={clientWalletOverviewData?.assetsAmount}
						outstandingBalance={
							selectedClientLoan?.outstandingBalance
						}
						interestRate={selectedClientLoan?.interestRate}
						yearsRemaining={selectedClientLoan?.tenureMonth}
						currentTermValue={selectedClientLoan?.currentTerm}
						refinancingTradelineId={selectedClientLoan?.tradelineId}
						loanType={selectedLoanType}
					/>
				)}

			{selectedLoanType === 'Reverse Mortgage' && selectedClient && (
				<ReverseMortgage
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					clientId={clientProfileDataWithFallBack?.id}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					annualIncome={clientWalletOverviewData?.annualIncome}
					outstandingBalanceValue={
						selectedClientLoan?.outstandingBalance
					}
					estimatedValue={
						selectedClientLoan?.clientHome?.estimatedValue
					}
					primaryUseValue={
						selectedClientLoan?.clientHome?.propertyType
					}
					addressLine1Value={
						selectedClientLoan?.clientHome?.addressLine1
					}
					addressLine2Value={
						selectedClientLoan?.clientHome?.addressLine2
					}
					cityValue={selectedClientLoan?.clientHome?.city}
					stateValue={selectedClientLoan?.clientHome?.state}
					zipCodeValue={selectedClientLoan?.clientHome?.zipcode}
					refinancingTradelineId={selectedClientLoan?.tradelineId}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Commercial Loan' && selectedClient && (
				<SmallBusinessLoan
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					clientId={clientProfileDataWithFallBack?.id}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					loanType={selectedLoanType}
				/>
			)}

			{selectedLoanType === 'Student Loan (Refinance)' &&
				selectedClient && (
					<StudentLoanRefinanceLoanForm
						isClientNameProvided={isClientNameProvided}
						advisorName={advisorName}
						advisorEmail={advisorEmail}
						advisorCompany={advisorCompany}
						advisorWebsite={advisorWebsite}
						annualIncome={clientWalletOverviewData?.annualIncome}
						assetsAmount={clientWalletOverviewData?.assetsAmount}
						clientId={clientProfileDataWithFallBack?.id}
						creditScore={clientProfileDataWithFallBack?.creditScore}
						firstName={
							clientProfileDataWithFallBack?.firstName || ''
						}
						lastName={clientProfileDataWithFallBack?.lastName || ''}
						employerValue={clientProfileDataWithFallBack?.employer}
						employmentStatusValue={
							clientProfileDataWithFallBack?.employmentStatus
						}
						educationLevelValue={
							clientProfileDataWithFallBack?.highestDegree
						}
						schoolValue={clientProfileDataWithFallBack?.school}
						graduationYearValue={
							clientProfileDataWithFallBack?.graduationYear
						}
						dobValue={clientProfileDataWithFallBack?.dob}
						addressLine1Value={
							clientProfileDataWithFallBack?.address?.addressLine1
						}
						addressLine2={
							clientProfileDataWithFallBack?.address?.addressLine2
						}
						cityValue={clientProfileDataWithFallBack?.address?.city}
						stateValue={
							clientProfileDataWithFallBack?.address?.state
						}
						zipCodeValue={
							clientProfileDataWithFallBack?.address?.zipcode
						}
						emailAddressValue={clientProfileDataWithFallBack?.email}
						lenderType={selectedClientLoan?.lenderType}
						outstandingBalance={
							selectedClientLoan?.outstandingBalance
						}
						refinancingTradelineId={selectedClientLoan?.tradelineId}
						loanType={selectedLoanType}
						currentInterestRateValue={
							selectedClientLoan?.interestRate
						}
						studentLoanType={selectedClientLoan?.subType}
					/>
				)}

			{selectedLoanType === 'Other' && selectedClient && (
				<OtherLoanForm
					clientId={clientProfileDataWithFallBack?.id}
					isClientNameProvided={isClientNameProvided}
					advisorName={advisorName}
					advisorEmail={advisorEmail}
					advisorCompany={advisorCompany}
					advisorWebsite={advisorWebsite}
					creditScoreValue={
						clientProfileDataWithFallBack?.creditScore
					}
					firstName={clientProfileDataWithFallBack?.firstName || ''}
					lastName={clientProfileDataWithFallBack?.lastName || ''}
					emailAddressValue={clientProfileDataWithFallBack?.email}
					loanType={selectedLoanType}
					addressLine1Value={
						clientProfileDataWithFallBack?.address?.addressLine1
					}
					addressLine2Value={
						clientProfileDataWithFallBack?.address?.addressLine2
					}
					cityValue={clientProfileDataWithFallBack?.address?.city}
					stateValue={clientProfileDataWithFallBack?.address?.state}
					zipCodeValue={
						clientProfileDataWithFallBack?.address?.zipcode
					}
				/>
			)}
		</>
	);
}

export default LoanForms;
